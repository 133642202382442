<template>
  <div class="full" id="full">
    <div class="order_nav d_f ali_c">
      <img src="./img/order_back.png" alt="" class="cur_p" @click="$router.go(-1)">
      <span>酒店详情</span>
    </div>
    <div class="hd_content hd_content_d" id="hd_content">
      <van-tabs v-model="active" scrollspy sticky :offset-top="0">
        <van-tab title="酒店详情" ref="one">
          <div class="tab_inner">
            <div class="card one t_a">
              <div class="title">{{info.hotelName}}</div>
              <div class="describe">
                <span>{{info.openYear ? info.openYear.split('-')[0] : '--'}}年开业 <span> | </span></span>
                <span>{{info.latestDecorateTime ? info.latestDecorateTime.split('-')[0] : '--'}}年装修 <span> | </span></span>
                <span>{{info.floorCount}}层 <span> | </span></span>
                <span>{{info.roomNum}}间 <span v-if="false"> | </span></span>
              </div>
              <a :href="'tel:'+info.telephone" class="d_i t_a tel">酒店电话：{{info.telephone}}</a>
              <div class="img_full">
                <div v-html="info.depiction"></div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="设施与服务" ref="two" v-if="BookingInstructionArr.length">
          <div class="tab_inner">
            <div class="card two">
              <div class="title">设施与服务</div>
              <div class="items" v-if="InstallationServiceArr.length">
                <div class="d_i" v-for="(item,index) in InstallationServiceArr" :key="index">{{item.name}}</div>
              </div>
              <div v-else class="items mt_12">暂无相关信息</div>
            </div>

          </div>
        </van-tab>
        <van-tab title="预订须知" ref="three" v-if="BookingInstructionArr.length">
          <div class="tab_inner">
            <div class="card three">
              <div class="title">预订须知</div>
              <div class="items" v-if="BookingInstructionArr.length">
                <div class="d_f ali_c" v-for="(item,index) in BookingInstructionArr" :key="index">
                  <div class="left">{{item.title}}</div>
                  <div class="right">{{item.value}}</div>
                </div>
              </div>
              <div v-else class="items">暂无相关信息</div>
            </div>

          </div>

        </van-tab>
      </van-tabs>
    </div>
    <div class="p_f btn t_a cur_p" @click="goToHotelInner">查看房间</div>

    <!--回到顶部-->
    <div class="back_top p_f cur_p" v-if="isShowBackTop" @click="goTop">
      <img src="./img/back_top.png" alt="">
      <div>返回顶部</div>
    </div>
  </div>
</template>

<script>
  import apis from './utils/apis'
  import { Tabs, Tab} from 'vant'

  export default {
    name: "hotelDetail",
    components:{
      'van-tabs' : Tabs,
      'van-tab' : Tab,
    },
    data() {
      return {
        active:0,
        isShowBackTop:false,
        info:{},
        InstallationServiceArr:[],//设施与服务
        BookingInstructionArr:[],//预定须知
      }
    },
    mounted(){
      window.addEventListener('scroll',this.scrollFunc,true);
      // this.active = this.$route.query.type != 0 ? Number(this.$route.query.type) : 0;
      this.getHotelInfoDetail();
    },
    created(){

      this.getShareInfo(
        this.$global.shareTitle,
        this.$global.visitUrl,
        this.$global.shareImage,
        this.$global.shareIntro,
      );
    },
    methods: {
      //查询预定须知
      getBookingInstruction(){
        let _t = this;
        _t.$get({
          url: apis.getBookingInstruction,
          params: {
            sellerId:_t.$route.query.id || 105262
          }
        }).then(rsp => {
          _t.BookingInstructionArr = rsp;
          this.$nextTick(()=>{
            setTimeout(function () {
              _t.active = _t.$route.query.type != 0 ? Number(_t.$route.query.type) : 0;
              if(!_t.BookingInstructionArr.length || !_t.InstallationServiceArr){
                _t.active = 0;
              }
              const btn = document.getElementsByClassName('van-tab')[_t.active];
              btn.onclick = function () { //

              };
              simulateClick();
              // 2. 模拟 浏览器的鼠标点击事件
              // 2.1 可以触发 onclick 事件（先触发）
              // 2.2 可以触发 addEventListener 事件（后触发）
              // 2.3 jQuery 的事件绑定底层就是 addEventListener ,
              function simulateClick() { // 模拟 浏览器的鼠标点击事件
                const event = new MouseEvent('click', {
                  view: window,
                  bubbles: true,
                  cancelable: true
                });
                btn.dispatchEvent(event);
              }
            },1000)
            // document.querySelector('van-tab').click()
            /*console.log(_t.$refs.one)
            console.log(_t.$refs.two)
            console.log(_t.$refs.three)
            _t.$refs.two.$el.click();*/

          })
        });
      },
      //查询设施与服务done
      getInstallationService(){
        this.$get({
          url: apis.getInstallationService,
          params: {
            sellerId:this.$route.query.id || 105262
          }
        }).then(rsp => {
          this.InstallationServiceArr = rsp;
          this.getBookingInstruction();
        });
      },
      //获取酒店详情
      getHotelInfoDetail(){
        this.$get({
          url: apis.getHotelInfoDetail,
          params: {
            sellerId:this.$route.query.id || 105262
          }
        }).then(rsp => {
          this.info = rsp;
          this.getInstallationService();
        });
      },
      //跳转到酒店内页
      goToHotelInner(){
        this.$router.push({path:'/hotelInner',query:{sellerId:this.$route.query.id}})
      },
      // 回到顶部
      goTop(){
        let interval = setInterval(function () {
          if(document.documentElement.scrollTop == 0){
            clearInterval(interval)
          }else{
            document.documentElement.scrollTop = document.documentElement.scrollTop-15;
          }
        },5)
      },
      scrollFunc(){
        let top = document.getElementById('app').scrollTop||document.documentElement.scrollTop;
        if(top>200){
          this.isShowBackTop = true
        }else{
          this.isShowBackTop = false
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  .back_top{
    right: 5px;
    bottom: 100px;
    width:50px;
    height:50px;
    background:linear-gradient(110deg,rgba(31,193,119,1),rgba(31,193,119,1));
    border-radius:50%;
    text-align: center;
    font-size:8px;
    font-weight:bold;
    color:rgba(255,255,255,1);
    padding-top: 7px;
    box-sizing: border-box;
    img{
      width: 15px;
    }
  }
  .btn{
    width: 100%;
    height: 51px;
    line-height: 51px;
    background:rgba(32,193,119,1);
    font-size:17px;
    font-weight:bold;
    color:rgba(255,255,255,1);
    bottom: 0;
  }
  .tab_inner{
    .card{
      &.three{
        .items{
          .right{
            flex: 1;
            font-weight:500;
          }
          .left{
            font-weight:bold;
            width: 62px;
          }
          font-size:13px;
          color:rgba(62,62,62,1);
          line-height: 43px;
          border-bottom: 1px solid #C0C0C0;
        }
      }
      &.two{
        .items{
          >.d_i{
            width: calc(100% / 3);
            font-size:13px;
            font-weight:500;
            color:rgba(62,62,62,1);
            margin-top: 15px;
            float: left;
          }
          &::after{
            content: ' ';
            display: block;
            clear: both;
          }
        }
      }
      .title{
        font-size:17px;
        font-weight:bold;
        color:rgba(62,62,62,1);
      }
      &.one{
        .tel{
          height:35px;
          border:1px solid rgba(32,193,119,1);
          border-radius:2px;
          line-height: 35px;
          width: 100%;
          font-size:13px;
          color:rgba(32,193,119,1);
          margin-bottom: 7px;
        }
        .describe{
          font-size:13px;
          margin-bottom: 15px;
        }
        .title{
          font-size:17px;
          margin-bottom: 17px;
        }
        font-weight:500;
        color:rgba(62,62,62,1);
        padding-top: 10px;
      }
      background-color: white;
      padding: 19px 13px;
    }
    background-color: #F0F0F0;
    padding: 13px 21px 0 21px;
  }
  .order_nav{
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    background: #20C177;
    z-index: 90;
    height:43px;
    span{
      color: white;
    }
  }
  .hd_content{
    background-color: #F0F0F0;
    padding: 43px 0 71px 0;
  }
  .full{
    box-sizing: border-box;
  }
</style>
